import types from '../mutation-types';
import BillingAPI from '../../api/billing';

export const state = {
  accountBillingInfo: null,
  countryBasePricing: null,
  numberPricing: null,
  uiFlags: {
    isFetching: false,
    userDismissedBanner: false,
  },
};

export const getters = {
  getBillingInfo($state) {
    return $state.accountBillingInfo;
  },
  getCountryBasePricing($state) {
    return $state.countryBasePricing;
  },
  getNumberPricing($state) {
    return $state.numberPricing;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getIsFetchingUIFlag($state) {
    return $state.uiFlags.isFetching;
  },
};

export const actions = {
  setUserDismissedBanner({ commit }, dismissed) {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, {
      userDismissedBanner: dismissed,
    });
  },
  getBillingInfo: async ({ commit }, accountId) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.getAccountBilling(accountId);
      commit(types.SET_ACCOUNT_BILLING, data);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  getCountryBasePricing: async ({ commit }, accountId) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.getCountryBasePricing(accountId);
      commit(types.SET_COUNTRY_BASE_PRICING, data);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  getNumberPricing: async ({ commit }, accountId) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.getNumberPricing(accountId);
      commit(types.SET_NUMBER_PRICING, data);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  buyChannel: async ({ commit }, data) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      await BillingAPI.buyChannel(data);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  deletePhoneNumber: async ({ commit }, data) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      await BillingAPI.deletePhoneNumber(data);
      commit('inboxes/' + types.REMOVE_PHONE_NUMBER, data, { root: true });
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  deleteCallBaseChannel: async ({ commit }, data) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      await BillingAPI.deleteCallBaseChannel(data);
      commit('inboxes/' + types.DELETE_INBOXES, data.inboxId, { root: true });
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  provisionNumber: async ({ commit }, data) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      await BillingAPI.provisionNumber(data);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  activateSubscription: async ({ commit }, reqData) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.activateSubscription(reqData);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      return data;
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  attachCard: async ({ commit }, reqData) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.attachCard(reqData);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      return data;
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  searchNumbers: async ({ commit }, reqData) => {
    commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BillingAPI.searchNumbers(reqData);
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      return data;
    } catch (error) {
      commit(types.SET_ACCOUNT_BILLING_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.SET_ACCOUNT_BILLING_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.SET_ACCOUNT_BILLING]($state, data) {
    $state.accountBillingInfo = data;
  },
  [types.SET_COUNTRY_BASE_PRICING]($state, data) {
    $state.countryBasePricing = data;
  },
  [types.SET_NUMBER_PRICING]($state, data) {
    $state.numberPricing = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
