<template>
  <div
    class="flex-container flex-dir-column medium-flex-dir-row"
    :class="isDateRangeSelected && 'filters-wrapper'"
  >
    <div
      v-if="type === 'agent'"
      class="small-12 medium-3 pull-right multiselect-wrap--small"
    >
      <p>
        {{ $t('AGENT_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multiselect
        v-model="currentSelectedFilter"
        :placeholder="multiselectLabel"
        label="name"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :show-labels="false"
        @input="changeFilterSelection"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="reports-option__wrap">
            <thumbnail
              :src="props.option.thumbnail"
              :status="props.option.availability_status"
              :username="props.option.name"
              size="22px"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">{{ props.option.name }}</span>
            </span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="reports-option__wrap">
            <thumbnail
              :src="props.option.thumbnail"
              :status="props.option.availability_status"
              :username="props.option.name"
              size="22px"
            />
            <p class="reports-option__title">{{ props.option.name }}</p>
          </div>
        </template>
      </multiselect>
    </div>
    <div
      v-if="type === 'agentbot'"
      class="small-12 medium-3 pull-right multiselect-wrap--small"
    >
      <p>
        {{ $t('AGENTBOT_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multiselect
        v-model="currentSelectedFilter"
        :placeholder="multiselectLabel"
        label="name"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :show-labels="false"
        @input="changeFilterSelection"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="reports-option__wrap">
            <thumbnail
              :src="props.option.thumbnail"
              :status="props.option.availability_status"
              :username="props.option.name"
              size="22px"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">{{ props.option.name }}</span>
            </span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="reports-option__wrap">
            <thumbnail
              :src="props.option.thumbnail"
              :status="props.option.availability_status"
              :username="props.option.name"
              size="22px"
            />
            <p class="reports-option__title">{{ props.option.name }}</p>
          </div>
        </template>
      </multiselect>
    </div>
    <div
      v-else-if="type === 'label'"
      class="small-12 medium-3 pull-right multiselect-wrap--small"
    >
      <p>
        {{ $t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multiselect
        v-model="currentSelectedFilter"
        :placeholder="multiselectLabel"
        label="title"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :multiple="true"
        :allow-empty="true"
        :show-labels="false"
        :close-on-select="false"
        style="min-width: 220px"
        @input="changeFilterSelection"
      >
        <template #selection="{ values, isOpen }">
          <div v-if="values.length > 2" v-show="!isOpen">
            <div
              v-for="(item, index) in values.slice(0, 2)"
              :key="item.id"
              v-tooltip="item.title"
              style="display: flex; align-items: center"
            >
              <div
                :style="{ backgroundColor: item.color }"
                class="reports-option__rounded--item"
              />
              <div class="title_wrapper">
                {{ item.title
                }}<span v-if="index < 1" class="margin-right-1">, </span>
              </div>
            </div>
            <div style="white-space: nowrap" class="margin-left-1">
              +{{ values.length - 2 }}
            </div>
          </div>
          <div v-else v-show="!isOpen" :style="!isOpen ? 'display:flex;' : ''">
            <div
              v-for="(item, index) in values"
              :key="item.id"
              style="display: flex"
            >
              <div
                :style="{ backgroundColor: item.color }"
                class="reports-option__rounded--item"
              />
              <div class="title_wrapper">
                {{ item.title }}<span v-if="index < values.length - 1">, </span>
              </div>
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div v-tooltip="props.option.title" class="reports-option__wrap">
            <input
              class="checked_label"
              type="checkbox"
              :checked="selectedItems.includes(props.option.title)"
            />
            <div
              :style="{ backgroundColor: props.option.color }"
              class="reports-option__rounded--item reports-option__item reports-option__label--swatch"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">
                {{ props.option.title }}
              </span>
            </span>
          </div>
        </template>
      </multiselect>
    </div>
    <div
      v-else-if="type === 'inbox' || type === 'team'"
      class="small-12 medium-3 pull-right multiselect-wrap--small"
    >
      <p>
        <template v-if="type === 'inbox'">
          {{ $t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL') }}
        </template>
        <template v-else-if="type === 'team'">
          {{ $t('TEAM_REPORTS.FILTER_DROPDOWN_LABEL') }}
        </template>
      </p>
      <multiselect
        v-if="type === 'inbox' || type === 'team'"
        v-model="currentSelectedFilter"
        track-by="id"
        label="name"
        :placeholder="multiselectLabel"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :options="filterItemsList"
        :searchable="false"
        :allow-empty="false"
        @input="changeFilterSelection"
      />
    </div>
    <div
      class="small-12 medium-3 pull-right margin-right-1 margin-left-1 multiselect-wrap--small"
    >
      <p>
        {{ $t('REPORT.DURATION_FILTER_LABEL') }}
      </p>
      <multiselect
        v-model="currentDateRangeSelection"
        track-by="name"
        label="name"
        :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :options="dateRange"
        :searchable="false"
        :allow-empty="false"
        @select="changeDateSelection"
      />
    </div>
    <div v-if="isDateRangeSelected" class="">
      <p>
        {{ $t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER') }}
      </p>
      <woot-date-range-picker
        show-range
        :value="customDateRange"
        :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
        :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
        @change="onChange"
      />
    </div>
    <div
      v-if="notLast7Days"
      class="small-12 medium-3 pull-right margin-right-1 multiselect-wrap--small"
    >
      <p>
        {{ $t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL') }}
      </p>
      <multiselect
        v-model="currentSelectedGroupByFilter"
        track-by="id"
        label="groupBy"
        :placeholder="$t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL')"
        :options="groupByFilterItemsList"
        :allow-empty="false"
        :show-labels="false"
        @input="changeGroupByFilterSelection"
      />
    </div>
    <div v-if="type !== 'agentbot'" class="small-12 medium-3 business-hours">
      <span class="business-hours-text">
        {{ $t('REPORT.BUSINESS_HOURS') }}
      </span>
      <span>
        <woot-switch v-model="businessHoursSelected" />
      </span>
    </div>
  </div>
</template>
<script>
import subDays from 'date-fns/subDays';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';

import { GROUP_BY_FILTER } from '../constants';
import {
  getDateTime,
  getTodayRange,
} from '../../../../../../shared/helpers/DateHelper';
import { isArray } from 'lodash';

const CUSTOM_DATE_RANGE_ID = 6;
const TODAY_RANGE_ID = 0;

export default {
  components: {
    WootDateRangePicker,
    Thumbnail,
  },
  props: {
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    groupByFilterItemsList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'agent',
    },
    selectedGroupByFilter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentSelectedFilter: null,
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[0],
      dateRange: this.$t('REPORT.DATE_RANGE'),
      customDateRange: [new Date(), new Date()],
      currentSelectedGroupByFilter: this.selectedGroupByFilter,
      businessHoursSelected: false,
      selectedItems: [],
    };
  },
  computed: {
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      if (this.currentDateRangeSelection.id === TODAY_RANGE_ID) {
        const { to } = getTodayRange();
        return to;
      }
      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      if (this.currentDateRangeSelection.id === TODAY_RANGE_ID) {
        const { from } = getTodayRange();
        return from;
      }
      const dateRange = {
        0: 1,
        1: 6,
        2: 29,
        3: 89,
        4: 179,
        5: 364,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
    multiselectLabel() {
      const typeLabels = {
        agent: this.$t('AGENT_REPORTS.FILTER_DROPDOWN_LABEL'),
        label: this.$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL'),
        inbox: this.$t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL'),
        team: this.$t('TEAM_REPORTS.FILTER_DROPDOWN_LABEL'),
      };
      return typeLabels[this.type] || this.$t('FORMS.MULTISELECT.SELECT_ONE');
    },
    groupBy() {
      if (this.isDateRangeSelected) {
        return GROUP_BY_FILTER[5].period;
      }
      const groupRange = {
        0: GROUP_BY_FILTER[1].period,
        1: GROUP_BY_FILTER[1].period,
        2: GROUP_BY_FILTER[4].period,
        3: GROUP_BY_FILTER[4].period,
        4: GROUP_BY_FILTER[4].period,
        5: GROUP_BY_FILTER[4].period,
      };
      return groupRange[this.currentDateRangeSelection.id];
    },
    notLast7Days() {
      return this.groupBy !== GROUP_BY_FILTER[2].period;
    },
  },
  watch: {
    currentSelectedFilter(newValue) {
      this.selectedItems = isArray(newValue)
        ? newValue?.map(i => i.title)
        : [newValue.title];
    },
    filterItemsList(val) {
      this.currentSelectedFilter = val[0];
      this.changeFilterSelection();
    },
    groupByFilterItemsList() {
      this.currentSelectedGroupByFilter = this.selectedGroupByFilter;
    },
    businessHoursSelected() {
      this.$emit('business-hours-toggle', this.businessHoursSelected);
    },
  },
  mounted() {
    this.onDateRangeChange();
  },
  methods: {
    onDateRangeChange() {
      this.$emit('date-range-change', {
        from: this.from,
        to: this.to,
        groupBy: this.groupBy,
      });
    },
    fromCustomDate(date) {
      return getDateTime(date);
    },
    toCustomDate(date) {
      return getDateTime(date);
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onDateRangeChange();
    },
    changeFilterSelection() {
      this.$emit('filter-change', this.currentSelectedFilter);
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
    },
    changeGroupByFilterSelection() {
      this.$emit('group-by-filter-change', this.currentSelectedGroupByFilter);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_reports';

.filters-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  @media screen and (max-width: 1100px) {
    grid-template-columns: auto auto;
  }
  .medium-3 {
    max-width: 100%;
  }
}

.align-center {
  display: flex;
  align-content: center;
}
.title_wrapper {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
</style>
