import axios from 'axios';
import wootConstants from 'dashboard/constants/globals.js';
import auth from './auth';

class BillingAPI {
  SERVICES_PATH = wootConstants.SERVICES_PATH;

  getAccountBilling(account_id) {
    const authToken = auth.getAuthToken();
    return axios.get(
      `/${this.SERVICES_PATH.BILLING}/v1/subscription-info?account_id=${account_id}`,
      { headers: { authorization: authToken } }
    );
  }

  getCountryBasePricing(data) {
    const authToken = auth.getAuthToken();
    return axios.get(
      `/${this.SERVICES_PATH.BILLING}/v1/call/base-pricing?account_id=${data.accountId}&countryCode=${data.countryCode}`,
      { headers: { authorization: authToken } }
    );
  }

  getNumberPricing(data) {
    const authToken = auth.getAuthToken();
    return axios.get(
      `/${this.SERVICES_PATH.BILLING}/v1/call/number-pricing?account_id=${data.accountId}`,
      { headers: { authorization: authToken } }
    );
  }

  buyChannel(data) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BILLING}/v1/call/buy-channel`,
      data,
      {
        headers: { authorization: authToken },
      }
    );
  }

  buyChannel(data) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BILLING}/v1/call/buy-channel`,
      data,
      {
        headers: { authorization: authToken },
      }
    );
  }

  provisionNumber(data) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BILLING}/v1/call/provision-number`,
      data,
      {
        headers: { authorization: authToken },
      }
    );
  }

  searchNumbers(data) {
    const authToken = auth.getAuthToken();
    const queryParams = new URLSearchParams({
      ...data,
    });
    return axios.get(
      `/${this.SERVICES_PATH.BILLING}/v1/call/search-numbers?${queryParams}`,
      { headers: { authorization: authToken } }
    );
  }

  activateSubscription(data) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BILLING}/v1/portal-session`,
      data,
      {
        headers: { authorization: authToken },
      }
    );
  }

  attachCard(data) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BILLING}/v1/card-checkout-session`,
      data,
      {
        headers: { authorization: authToken },
      }
    );
  }

  deletePhoneNumber(data) {
    const authToken = auth.getAuthToken();
    return axios.delete(`/${this.SERVICES_PATH.BILLING}/v1/call/phone-number`, {
      headers: { authorization: authToken },
      data,
    });
  }

  deleteCallBaseChannel(data) {
    const authToken = auth.getAuthToken();
    return axios.delete(
      `/${this.SERVICES_PATH.BILLING}/v1/call/call-base-channel`,
      {
        headers: { authorization: authToken },
        data,
      }
    );
  }
}

export default new BillingAPI();
